<template>
  <div class="stock-list main-panel large">
    <h3 class="panel-title">库存结存表</h3>
    <div class="top">
      <el-form inline>
        <el-form-item label="产品">
          <el-select :size="controlSize" filterable v-model="form.product" placeholder="请选择产品">
            <el-option v-for="item in products" :key="item.id" :label="item.pname" :value="item.id">
              <span style="float: left">{{ item.pname }}</span>
              <span style="float: right; color: #8492a6; font-size: 12px">{{ item.fabricName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="仓库">
          <el-select :size="controlSize" filterable v-model="form.department" placeholder="请选择仓库">
            <el-option v-for="item in departments" :key="item.id" :label="item.wareName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="numberRange" :size="controlSize" placeholder="例：0 或 0-50" />
        </el-form-item>
        <el-form-item>
          <el-button type="danger" :size="controlSize" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list" height="500" v-loading="isLoading">
      <el-table-column align="center" prop="departmentName" label="仓库"></el-table-column>
      <el-table-column align="center" prop="productName" label="产品名"></el-table-column>
      <el-table-column align="center" prop="fabricName" label="胚布名"></el-table-column>
      <el-table-column align="center" prop="number" label="数量"></el-table-column>

      <template #append>
        <div class="load-more"
             :class="{'no-more': noMore}"
             v-if="list.length"
             @click="getList">{{ noMore ? "没有数据了~" : "点击加载下一页" }}</div>
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      controlSize: "mini",
      form: {
        productId: null,
        departmentId: null,
        currentPage: 1,
        pageSize: 50
      },
      numberRange: "",

      products: [],
      departments: [],
      list: [],
      noMore: false,
      isLoading: false
    }
  },

  async mounted() {
    this.products = await this.$getList.getProducts()
    this.departments = await this.$getList.getDepartments()
    await this.getList()
  },

  methods: {
    async search() {
      this.noMore = false;
      this.form.currentPage = 1;
      this.list = []
      await this.getList();
    },

    async getList() {
      if (this.noMore) return false;
      this.isLoading = true
      const sendData = {...this.form}
      if (this.numberRange) {
        const numberRange = this.numberRange.split('-')
        if (numberRange.length > 1) {
          sendData.startNumber = +numberRange[0]
          sendData.endNumber = +numberRange[1]
        } else {
          sendData.startNumber = +numberRange[0]
          sendData.endNumber = +numberRange[0]
        }
      }

      const res = await this.$api.Report.Stock.getList(sendData).catch(() => {});
      this.isLoading = false
      if (res?.code === 200) {
        this.form.currentPage += 1;
        this.list.push(...res.data.data);
        if (this.list.length >= res.data.total) {
          this.noMore = true;
        }
        return true;
      }
      this.$message.error("查询失败");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "stock-list";
</style>
